.dynamicTextAreaContainer {
    width: 100%;
    height: 100%;
    
}

textarea {
    height: 100%;
    width: 100%;
    max-height: inherit;
    resize: none;
    display: block;
    border: none;
    overflow-y: scroll;
}