.homepage-step-wrapper {
  /* border: solid 1px black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 22%;
}

.homepage-step-label {
  text-align: center;
  font: normal normal bold 22px/35px Noto Sans;
  letter-spacing: -0.87px;
  color: #FFFFFF;
  opacity: 0.5;
  margin-bottom: 12px;
}

#homepage-steps-bar {
  width: 52%;
  height: 12px;
  background: #0B4863 0% 0% no-repeat padding-box;
  border-radius: 15px;
  position: absolute;
  top: 55px;
}

.homepage-step-dot {
  width: 28px;
  height: 28px;
  background: #153146 0% 0% no-repeat padding-box;
  border-radius: 50%;
  border: 5px solid #1EC3CC;
  z-index: 1;
  margin-bottom: 12px;
}

.homepage-step-dot.active {
  width: 28px;
  height: 28px;
  background: #1EC3CC 0% 0% no-repeat padding-box;
  border-radius: 50%;
  border: none;
  z-index: 1;
  margin-bottom: 12px;
}

#homepage-body {
  height: calc(100% - 87px);
}

#dropzone {
  color: var(--med-grey);
  border: 3px dashed #1EC3CC;
  border-radius: 15px;
  height: 60%;
  text-align: center;
  padding: 16px;
  box-sizing: border-box;
  margin-top: 2em;
  background: #123147 0% 0% no-repeat padding-box;
}

#dropzone-upper {
  height: 60%;
}

#dropzone-lower {
  height: 40%;
}

.dropzone-msg-wrapper {
  min-height: 100px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.5em;
}

.dropzone-msg {
  font: normal normal bold 40px/50px Noto Sans;
  letter-spacing: -1.2px;
  color: #FFFFFF;
  opacity: 0.11;
}

#dropzone-supported-msg {
  font-size: 16px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 0.29;
}

.theme-btn {
  background: #21C1FF 0% 0% no-repeat padding-box;
  border-radius: 100px;
  border: none;
  opacity: 1;
  color: white;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
}

#dropzone-btn {
  width: 200px;
}

#dropzone.drag-hover {
  background-color: rgba(0, 0, 0, 0.3);
  /* background: #123147 0% 0% no-repeat padding-box; */
}

#dropzone-file-input {
  display: none;
  opacity: 0;
  /* hide button too */
}

.dictionary-editor-wrapper {
  width: 100%;
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  overflow: hidden;
  /* box-sizing: border-box; */
}

.dictionary-header {
  text-align: left;
  font: normal normal bold 40px/50px Noto Sans;
  letter-spacing: -1.2px;
  color: #FFFFFF;
}

.dictionary-body {
  text-align: left;
  font-size: 22px;
  line-height: 28px;
  /* font: normal normal normal 22px/28px; */
  letter-spacing: -0.66px;
  color: #FFFFFF;
}

.dictionary-options input[type='checkbox'] {
  width: 28px;
  height: 28px;
}

.parse-type-dropdown button {
  width: 80%;
}

.parse-type-dropdown i {
  color: #1EC3CC;
  -webkit-text-stroke: 1px;
  float: right;
}

#parse-document-btn {
  width: 50%;
}

.dictionary-editor-header {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.66px;
  color: #1E293E;
  padding: 12px 0;
}

.dictionary-editor-body {
  height: calc(100% - 60px);
}

.preview-title {
  font: normal normal bold 40px/50px Noto Sans;
  letter-spacing: -1.2px;
  color: #FFFFFF;
}

.parse-waiting-msg {
  font-size: 20px;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-align: center;
}

.preview-content {
  height: calc(100% - 160px);
}

.preview-subtitle {
  letter-spacing: -0.66px;
  color: #FFFFFF;
}

.preview-wrapper {
  /* height: calc(100% - 160px); */
  border: 1px solid #000;
  padding: 12px;
  box-sizing: border-box;
  overflow-x: scroll;
  overflow-y: scroll;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-color: white;
}

.preview-wrapper::-webkit-scrollbar {
  width: 12px;
  /* height: 8px; */
  
}

/* Track */
.preview-wrapper::-webkit-scrollbar-track {
  background: #EBEBEB;
}

/* Handle */
.preview-wrapper::-webkit-scrollbar-thumb {
  background: #1EC3CC;
  border-radius: 5px;
}

#accept-preview-btn {
  width: 200px;
}

#purchase-btn {
  width: 300px;
}

#start-over-btn {
  width: 300px;
  background-color: #00C4CD !important;
}

.back-arrow {
  cursor: pointer;
}

#main-modal {
  display: block;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-show {
  visibility: visible !important;
}

.modal-dialog {
  /* position: absolute; */
  /* width: 240px; */
  top: 50%;
  /* left: 50%; */
  transform: translate(0, -50%);
  
}

.modal-content {
  background: #123147 0% 0% no-repeat padding-box;
  color: white;
  border: solid 1px #00C4CD;
  /* border-radius: 12px; */
}

































#dropzone-staged-file {
  align-items: center;
  display: none;
  flex-direction: column;
  margin-bottom: 12px;
}

#dropzone-staged-file.selected {
  background-color: var(--light-grey);
}



.dictionary-wrapper {
  /* background-color: red; */
  height: calc(100% - 248px);
  max-height: calc(100% - 248px);
  display: flex;
  flex-direction: column;
}

.dictionary {
  margin-top: 24px;
}

.dictionary-heading-row {
  height: 144px;
}

.dictionary__heading {
  margin: 24px 0;
  color: var(--med-grey);
}

.dictionary-picker-row {
  text-align: center;
}

.dictionary-picker__select {
  width: 200px;
  background-color: #00b7c4;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
}

.dictionary-picker__select option {
  background-color: #fff;
  color: #000;
}



.left-column {
  height: 100%;
}

.middle-column {
  text-align: center;
  align-items: center;
  height: 100%;
}

.middle-inner {
  /*margin-top: 36px;*/
  max-width: 560px;
  width: 100%;
  height: 60%;
}

.right-column {
  height: 100%;
}
















@media only screen and (max-height: 760px) {

  .contact {
    margin-top: 24px;
  }
}