.title {
    font-size: 48px;
    color: var(--scion-blue);
}

.is-downloading-wrapper {
    height: 80px;
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    text-align: center; */
}

.payment-success-logo {
    margin-top: 24px;
    width: 360px;
}

.payment-success-logo__img {
    width: 100%;
}