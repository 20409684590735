:root {
  --light-grey: #eeeeee;
  --med-grey: #777;
  --scion-blue: #00b7c4;
}

.App {
  width: 100vw;
  height: 100vh;
  /* padding: 16px; */
}