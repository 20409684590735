#root-container {
    background: transparent linear-gradient(180deg, #21283C 0%, #003E55 100%) 0% 0% no-repeat padding-box;
}

#root-container-backdrop {
    background-image: url(../images/backdrop.svg);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 0px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

#root-header {
    height: 124px;
}

#root-header img {
    padding: 28px 20px;
}

#root-body {
    height: calc(100vh - 124px);
    z-index: 2;
    position: relative;
}